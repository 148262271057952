import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "me-4" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-flex btn-light btn-active-primary fw-bolder",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_6 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2" }
const _hoisted_10 = { class: "card-body pt-0" }
const _hoisted_11 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_12 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "svg-icon svg-icon-3" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_registeredTradesFilter = _resolveComponent("registeredTradesFilter")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'registered-trades'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
              ]),
              _createVNode(_component_registeredTradesFilter, {
                onFilterSelected: _ctx.onTypeChange,
                onReset: _ctx.onReset
              }, null, 8, ["onFilterSelected", "onReset"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_ctx.can('create', 'registered-trades'))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('registered-trades-creating')))
                  }, [
                    _createElementVNode("span", _hoisted_9, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('createNewRegisteredTrades')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 10,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-id": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.id), 1)
            ]),
            "cell-transactionDate": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.transactionDate), 1)
            ]),
            "cell-descriptionAr": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.descriptionAr), 1)
            ]),
            "cell-areaName": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.areaName), 1)
            ]),
            "cell-propertyTypeName": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.propertyTypeName), 1)
            ]),
            "cell-price": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.price), 1)
            ]),
            "cell-size": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.size), 1)
            ]),
            "cell-pricePerM2": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.pricePerM2), 1)
            ]),
            "cell-notes": _withCtx(({ row: registeredTrade }) => [
              _createTextVNode(_toDisplayString(registeredTrade.notesAr), 1)
            ]),
            "cell-isActive": _withCtx(({ row: registeredTrade }) => [
              (registeredTrade.isActive)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.translate('active')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.translate('inactive')), 1))
            ]),
            "cell-actions": _withCtx(({ row: registeredTrade }) => [
              _createElementVNode("a", {
                href: "#",
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                onClick: _withModifiers(($event: any) => (_ctx.editRegisteredTrades(registeredTrade.id)), ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_14, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                ])
              ], 8, _hoisted_13),
              (_ctx.can('remove', 'registered-trades'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                    onClick: _withModifiers(($event: any) => (_ctx.toggleStatusRegisteredTrades(registeredTrade)), ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_16, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen019.svg" })
                    ])
                  ], 8, _hoisted_15))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}